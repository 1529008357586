define('time-clock-client/app', ['exports', 'ember', 'time-clock-client/resolver', 'ember-load-initializers', 'time-clock-client/config/environment', 'emberx-select/components/x-option'], function (exports, _ember, _timeClockClientResolver, _emberLoadInitializers, _timeClockClientConfigEnvironment, _emberxSelectComponentsXOption) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _timeClockClientConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _timeClockClientConfigEnvironment['default'].podModulePrefix,
    Resolver: _timeClockClientResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _timeClockClientConfigEnvironment['default'].modulePrefix);

  //originally lifted from http://stackoverflow.com/questions/32078951/how-to-implement-a-proper-global-http-error-handling-in-ember
  _ember['default'].Component.reopen({
    flash: _ember['default'].inject.service('flash-messages'),
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),

    actions: {
      // Passing ajaxError per default
      ajaxError: function ajaxError(error) {
        this.sendAction('ajaxError', error);
      }
    }
  });

  _emberxSelectComponentsXOption['default'].reopen({
    /**
      * Property bound to the `selected` attribute of the native
      * `<option>` element. It is aware of the containing `x-select`'s
      * value and will mark itself if it is the same.
      *
      * @private
      * @property selected
      * @type Boolean
      */
    selected: _ember['default'].computed('value', 'select.value', 'select.multiple', function () {
      if (this.get('select.multiple') && _ember['default'].isArray(this.get('select.value'))) {
        var selectValue = _ember['default'].A(this.get('select.value'));

        return selectValue.contains(this.get('value'));
      } else {
        return this.get('value') === this.get('select.value.content');
      }
    })
  });

  exports['default'] = App;
});