define('time-clock-client/routes/profile/time-clock', ['exports', 'ember', 'time-clock-client/routes/base-route'], function (exports, _ember, _timeClockClientRoutesBaseRoute) {
  exports['default'] = _timeClockClientRoutesBaseRoute['default'].extend({
    model: function model(params) {
      return this.get('store').findRecord('associate', params.associateId);
    },

    afterModel: function afterModel(model) {
      var _this = this;

      var promises = {
        timeClockEntries: this.get('store').query('timeClockEntry', { associate_id: model.get('id'), last_24_hours: true })
      };

      return _ember['default'].RSVP.hash(promises).then(function (result) {
        _this.set('timeClockEntries', result.timeClockEntries.toArray());
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("timeClockEntries", this.get('timeClockEntries'));
    }

  });
});