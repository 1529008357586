define('time-clock-client/models/time-clock-entry', ['exports', 'ember', 'ember-data', 'ember-data/model', 'ember-data/relationships', 'moment'], function (exports, _ember, _emberData, _emberDataModel, _emberDataRelationships, _moment) {
  exports['default'] = _emberDataModel['default'].extend({

    clockedIn: _emberData['default'].attr('date'),
    clockedOut: _emberData['default'].attr('date'),

    profile: (0, _emberDataRelationships.belongsTo)('profile'),
    associate: (0, _emberDataRelationships.belongsTo)('associate'),
    project: (0, _emberDataRelationships.belongsTo)('project'),
    task: (0, _emberDataRelationships.belongsTo)('task'),

    selected: false,

    isClockedIn: _ember['default'].computed('clockedIn', 'clockedOut', function () {
      return !!(this.get('clockedIn') && !this.get('clockedOut'));
    }),

    isComplete: _ember['default'].computed('clockedIn', 'clockedOut', function () {
      return !!(this.get('clockedIn') && this.get('clockedOut'));
    }),

    totalTime: _ember['default'].computed('clockedOut', function () {
      var totalTime = 0;
      if (this.get('isComplete')) {
        var clockOut = (0, _moment['default'])(this.get('clockedOut'));
        var clockIn = (0, _moment['default'])(this.get('clockedIn'));
        totalTime = clockOut.diff(clockIn);
      }
      return totalTime;
    })

  });
});