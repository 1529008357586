define('time-clock-client/mirage/factories/user', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  exports['default'] = _emberCliMirage.Factory.extend({
    firstName: function firstName() {
      return faker.name.firstName();
    },
    lastName: function lastName() {
      return faker.name.lastName();
    },
    email: function email(i) {
      return 'email' + i + '@acme.com';
    },
    password: function password() {
      return 'sss777';
    },
    token: function token() {
      return null;
    }

  });
});