define('time-clock-client/models/profile', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports['default'] = _emberDataModel['default'].extend({

    name: (0, _emberDataAttr['default'])('string'),
    fs_uid: (0, _emberDataAttr['default'])('string'),

    associates: (0, _emberDataRelationships.hasMany)('associate'),
    projects: (0, _emberDataRelationships.hasMany)('project'),
    user: (0, _emberDataRelationships.belongsTo)('user')
  });
});
// /app/models/profile.js