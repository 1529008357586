define('time-clock-client/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {

    actions: {

      error: function error(_error, transition) {
        // transition.promise.finally( () => {
        //   this.send('ajaxError', error); 
        // })   
      },

      ajaxError: function ajaxError(error) {
        this.ajaxError(error);
      }
    }

  });
});
//app/routes/application.js