define('time-clock-client/components/associate-pin-pad', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    associatePin: "",
    profile: null,
    associate: null,

    store: _ember['default'].inject.service('store'),
    session: _ember['default'].inject.service('session'),

    sortedAssociateList: _ember['default'].computed.sort('associateList', 'sortDefinition'),
    sortDefinition: ['firstName', 'lastName'],

    associateList: _ember['default'].computed('profile', function () {
      if (!this.get('profile')) {
        return [];
      }
      return this.get('store').query('associate', { profile_fs_uid: this.get('profile.fs_uid') });
    }),

    credentialObserver: _ember['default'].observer('associateId', 'associatePin', function () {
      _ember['default'].get(this, 'flash').clearMessages();
      if (this.get('associatePin').length < 4) {
        return;
      }
      if (this.get('associatePin').length === 4 && this.get('associateId')) {
        this.authenticate();
      } else if (this.get('associatePin').length === 4 && !this.get('associateId')) {
        this.get('flash').info('Please select your name from the associate list');
      }
    }),

    authenticate: function authenticate() {
      var _this = this;

      var _getProperties = this.getProperties('associateId', 'associatePin');

      var associateId = _getProperties.associateId;
      var associatePin = _getProperties.associatePin;

      this.get('session').authenticateAssociate(associateId, associatePin).then(function () {
        _this.get('router').transitionTo('profile.time-clock', associateId);
      }, function () {
        //TODO: lockout after x # of attempts?
        _this.send('clearPin');
      });
    },

    actions: {
      updatePin: function updatePin(pinDigit) {
        this.set('associatePin', this.get('associatePin') + pinDigit);
      },

      clearPin: function clearPin() {
        this.set('associatePin', "");
      },

      backSpace: function backSpace() {
        this.set('associatePin', this.get('associatePin').slice(0, -1));
      }
    }

  });
});