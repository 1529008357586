define('time-clock-client/mirage/scenarios/default', ['exports'], function (exports) {
  exports['default'] = function (server) {

    /*
      Seed your development database using your factories.
      This data will not be loaded in your tests.
       Make sure to define a factory for each model you want to create.
    */
    var douglas = server.create('user', { firstName: 'Doug', lastName: "Mat", email: 'dm@fs.com', token: 'admin-user-token', theme: 'ocean' });
    var tucker = server.create('user', { firstName: 'Tucker', lastName: "Mathis", email: 'tm@fs.com', token: 'admin-user-token', theme: 'water' });
    var mike = server.create('user', { firstName: 'Mike', lastName: "Savory", email: 'ms@fs.com', token: 'admin-user-token' });

    //server.createList('user', 3)
    server.createList('profile', 2);
    // server.createList('profile', 3, {user: douglas});
    // server.createList('profile', 3, {user: tucker});
    // server.createList('profile', 3, {user: mike});

    // server.createList('user', 3);
    var p = server.create('profile', { name: "Profile with Associates" });
    // let pMike = server.create('profile', {user: mike});
    // let pTucker = server.create('profile', {user: tucker});
    // let pDoug = server.create('profile', {user: douglas});

    server.createList('associate', 4, { profile: p });
    //server.createList('associate', 4, {profile: pDoug});

    var pProjects = server.create('profile', { name: "Projects Enabled Profile" });

    server.create('associate', { firstName: 'Project', lastName: "Associate Pin:5555", email: 'proj@enabled.com', pin: 5555, profile: pProjects, projectEnabled: true });

    var project1 = server.create('project', { profile: pProjects });
    var project2 = server.create('project', { profile: pProjects });

    server.createList('task', 2, { project: project1 });
    server.createList('task', 1, { project: project2 });
  };
});