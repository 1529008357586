define('time-clock-client/serializers/application', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var underscore = _ember['default'].String.underscore;

  exports['default'] = _emberData['default'].JSONAPISerializer.extend({
    keyForAttribute: function keyForAttribute(attr) {
      return underscore(attr);
    },

    keyForRelationship: function keyForRelationship(rawKey) {
      return underscore(rawKey);
    }
  });
});
// app/serializers/application.js