define('time-clock-client/components/time-clock', ['exports', 'ember', 'moment', 'npm:humanize-duration'], function (exports, _ember, _moment, _npmHumanizeDuration) {
  exports['default'] = _ember['default'].Component.extend({
    associate: null,
    timeClockEntries: null,
    projects: _ember['default'].A(),
    showProjects: false,

    session: _ember['default'].inject.service('session'),

    sortDefinition: ['clockedIn:desc'],
    sortedTimeClockEntries: _ember['default'].computed.sort('timeClockEntries', 'sortDefinition'),

    currentDate: _ember['default'].computed('', function () {
      return (0, _moment['default'])().format('DD/MM/YYYY');
    }),

    shouldCreateNewTimeRow: _ember['default'].computed.not('incompleteTimeEntry'),

    incompleteTimeEntry: _ember['default'].computed('timeClockEntries.@each.isComplete', function () {
      return this.get('timeClockEntries').filterBy('isComplete', false).length > 0;
    }),

    newTimeClockEntry: _ember['default'].computed('shouldCreateNewTimeRow', function () {
      var newEntry = this.get('store').createRecord('timeClockEntry', { associate: this.get('associate') });
      newEntry.set('selected', true);
      return newEntry;
    }),

    selectedEntry: _ember['default'].computed('timeClockEntries.@each.selected', function () {
      return this.get('timeClockEntries').findBy('selected', true);
    }),

    associateIsCurrentlyClockedIn: _ember['default'].computed('selectedEntry', function () {
      return !!(this.get('selectedEntry') && this.get('selectedEntry.isClockedIn'));
    }),

    currentDayTimeTotal: _ember['default'].computed('timeClockEntries.@each.totalTime', function () {
      return this.get('timeClockEntries').mapBy('totalTime').reduce(function (prevVal, elem) {
        return prevVal + elem;
      }, 0);
    }),

    currentTimeTotalDisplay: _ember['default'].computed('currentDayTimeTotal', function () {
      return (0, _npmHumanizeDuration['default'])(this.get('currentDayTimeTotal'));
    }),

    shouldDisplayTodaysHours: _ember['default'].computed('timeClockEntries.@each.isComplete', function () {
      return this.get('timeClockEntries').filterBy('isComplete').length >= 1;
    }),

    associateCanSelectProjects: _ember['default'].computed('associate.projectEnabled', function () {
      return !!(this.get('associate') && this.get('associate.projectEnabled'));
    }),

    profileProjects: _ember['default'].computed('associate', function () {
      //TODO: uncomment once backend route is implemented
      return null;
      //return this.get('store').query('project', {profileId: this.get('associate.profile.id')});
    }),

    actions: {
      showProjects: function showProjects() {
        this.set('showProjects', true);
      },

      hideProjects: function hideProjects() {
        this.set('showProjects', false);
      },

      addTimeClockEntry: function addTimeClockEntry(timeClockEntry) {
        this.get('timeClockEntries').pushObject(timeClockEntry);
      },

      associateSignOut: function associateSignOut() {
        var _this = this;

        var associateId = this.get('associate.id');
        this.get('session').invalidateAssociate(associateId).then(function () {
          _this.get('router').transitionTo('profile.associate-sign-in');
        }, function () {
          alert('Error signing associate out');
        });
      }
    }

  });
});
//app/components/time-clock.js