define('time-clock-client/components/time-row', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Component.extend({
    timeClockEntry: null,
    projects: null,
    showProjects: false,

    associate: _ember['default'].computed.alias('timeClockEntry.associate'),
    project: _ember['default'].computed.alias('timeClockEntry.project'),
    task: _ember['default'].computed.alias('timeClockEntry.task'),
    clockedIn: _ember['default'].computed.alias('timeClockEntry.clockedIn'),
    clockedOut: _ember['default'].computed.alias('timeClockEntry.clockedOut'),
    isCurrentlyClockedIn: _ember['default'].computed.alias('timeClockEntry.isClockedIn'),
    isEntryComplete: _ember['default'].computed.alias('timeClockEntry.isComplete'),
    timeEntryTotalTime: _ember['default'].computed.alias('timeClockEntry.totalTime'),

    clockedInDisplay: _ember['default'].computed('clockedIn', function () {
      return (0, _moment['default'])(this.get('clockedIn')).format('h:mm:ss A');
    }),

    clockedOutDisplay: _ember['default'].computed('clockedOut', function () {
      return (0, _moment['default'])(this.get('clockedOut')).format('h:mm:ss A');
    }),

    shouldShowClockInButton: _ember['default'].computed('isCurrentlyClockedIn', 'clockedOut', function () {
      return !(this.get('isCurrentlyClockedIn') || this.get('clockedOut'));
    }),

    timeEntryDisplay: _ember['default'].computed('timeEntryTotalTime', function () {
      var totalTime = this.get('timeEntryTotalTime');
      var seconds = ("0" + Math.round(totalTime / 1000 % 60)).slice(-2);
      var minutes = ("0" + Math.floor(totalTime / (1000 * 60) % 60)).slice(-2);
      var hours = Math.floor(totalTime / (1000 * 60 * 60) % 24);
      return hours + ':' + minutes + ':' + seconds;
    }),

    projectTasks: _ember['default'].computed('project', function () {
      if (this.get('project.id')) {
        return this.get('store').query('task', { projectId: this.get('project.id') });
      }
      return _ember['default'].A();
    }),

    associateCanSelectProjects: _ember['default'].computed('associate.projectEnabled', 'showProjects', function () {
      return !!(this.get('associate.projectEnabled') && this.get('showProjects'));
    }),

    showProjectInfo: _ember['default'].computed('project', 'isEntryComplete', function () {
      return !!(this.get('project.content') && this.get('isEntryComplete'));
    }),

    editProjectInfo: _ember['default'].computed('showProjects', 'isEntryComplete', function () {
      return !!(this.get('showProjects') && !this.get('isEntryComplete'));
    }),

    actions: {
      updateProject: function updateProject(project) {
        this.set('project', project);
      },

      updateTask: function updateTask(task) {
        this.set('task', task);
      },

      clockIn: function clockIn() {
        var _this = this;

        this.saveTime('clockedIn', function () {
          _this.sendAction('addEntry', _this.get('timeClockEntry'));
        }, function () {
          _this.get('flash').danger('There was a problem clocking you in');
        });
      },

      clockOut: function clockOut() {
        var _this2 = this;

        this.saveTime('clockedOut', function () {
          _this2.set('timeClockEntry.selected', false);
        }, function () {
          _this2.get('flash').danger('There was a problem clocking you out');
        });
      }
    },

    saveTime: function saveTime(clockProp, afterSaveHandler, saveErrorHandler) {
      var _this3 = this;

      var date = new Date();
      date.setMilliseconds(0);

      clockProp = 'timeClockEntry.' + clockProp;
      this.set(clockProp, date);
      this.get('timeClockEntry').save().then(function () {
        afterSaveHandler();
      }, function () {
        _this3.set(clockProp, null);
        saveErrorHandler();
        _this3.sendAction('ajaxError', _this3.get('timeClockEntry.errors'));
      });
    }

  });
});
//app/components/time-row.js