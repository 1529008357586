define('time-clock-client/helpers/should-mute', ['exports', 'ember'], function (exports, _ember) {
  exports.shouldMute = shouldMute;

  function shouldMute(params) {
    var associatePin = params[0];
    var iconIndex = params[1];

    if (associatePin.length >= iconIndex) {
      return true;
    }
    return false;
  }

  exports['default'] = _ember['default'].Helper.helper(shouldMute);
});