define('time-clock-client/mirage/config', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  exports['default'] = function () {

    // These comments are here to help you get started. Feel free to delete them.

    /*
      Config (with defaults).
       Note: these only affect routes defined *after* them!
    */
    this.passthrough();
    //this.passthrough('/authenticate');
    //this.passthrough('/associate-authenticate');

    //this.urlPrefix = '';    // make this `http://localhost:8080`, for example, if your API is on a different server
    //this.namespace = 'api/v1';    // make this `api`, for example, if your API is namespaced
    //this.timing = 400;      // delay for each request, automatically set to 0 during testing

    /*
      Shorthand cheatsheet:
       this.get('/posts');
      this.post('/posts');
      this.get('/posts/:id');
      this.put('/posts/:id'); // or this.patch
      this.del('/posts/:id');
       http://www.ember-cli-mirage.com/docs/v0.2.0-beta.7/shorthands/
    */

    /*
      This is the admin sign in end point.
      should we update update the last login info on the finsync db with info related to the clock?
    */
    this.post('/authenticate', function (schema, request) {
      var params = JSON.parse(request.requestBody);
      var user = schema.users.where({ email: params.username, password: params.password }).models.shift();

      if (user) {
        user.update('token', 'admin-user-token');
        return { token: user.token };
      } else {
        return new _emberCliMirage.Response(401, {}, {});
      }
    });

    //TODO: Implement on the server Note: This may not be needed
    this.get('/users', function (schema, request) {
      if (request.requestHeaders.Authorization) {
        var user = schema.users.where({ token: request.requestHeaders.Authorization.split(' ')[1] }).models[0];
        return user;
      } else {
        return schema.users.all();
      }
    });

    //TODO: Implement on the server NOTE: May not need this one
    this.get('/users/current', function (schema, request) {
      if (request.requestHeaders.Authorization) {
        var user = schema.users.where({ token: request.requestHeaders.Authorization }).models.first;
        return user;
      } else {
        return new _emberCliMirage.Response(401, {}, {});
      }
    });

    this.get('/profiles', function (schema, request) {
      if (request.requestHeaders.Authorization) {
        return schema.profiles.all();
      } else {
        return (0, _emberCliMirage.Response)(401, {}, {});
      }
    });

    //TODO: Implement on the server or remove NOTE: Dont think we need this one now
    // this.get('/profiles/:id', (schema, request) => {
    //   if(request.requestHeaders.Authorization) {
    //     return schema.profiles.find(request.params.id);
    //   }else{
    //     return new Response(401, {}, {});
    //   }
    // });

    this.get('/associates', function (schema, request) {
      if (request.requestHeaders.Authorization) {
        return schema.associates.where({ profileId: request.queryParams['profileId'] });
      } else {
        return new _emberCliMirage.Response(401, {}, {});
      }
    });

    //TODO: Implement on the server NOTE: May not need this one
    this.get('/associates/current', function (schema, request) {
      if (request.requestHeaders.Authorization) {
        //find the associate with the auth token in the request
        return { user: { id: 1, firstName: '', lastName: 'McCarthy' } };
      } else {
        return new _emberCliMirage.Response(401, {}, {});
      }
    });

    //TODO: Implement on the server
    this.post('/associate-authenticate', function (schema, request) {
      var params = JSON.parse(request.requestBody);
      var associate = schema.associates.where({ id: params.id, pin: params.pin }).models.shift();
      if (associate) {
        associate.update('token', 'admin-user-token');
        return { token: associate.token };
      } else {
        return new _emberCliMirage.Response(401, {}, {});
      }
    });

    //TODO: Implement on the server or remove NOTE: May not need this one now
    this.get('/associates/:associateId', function (schema, request) {
      if (request.requestHeaders.Authorization) {
        return schema.associates.find(request.params['associateId']);
      } else {
        return (0, _emberCliMirage.Response)(401, {}, {});
      }
    });

    //TODO: Implement on the server NOTE: Only need one of the following two functions
    this.patch('/associate-token-invalidation', function (schema, request) {
      return new _emberCliMirage.Response(200, {}, {});
    });

    //TODO: Implement on the server or remove NOTE: This is the assoicate logout
    this.patch('/associates/:id', function (schema, request) {
      if (request.requestHeaders.Authorization) {
        var associate = schema.tasks.where({ projectId: request.queryParams.projectId });
        return tasks;
      } else {
        return new _emberCliMirage.Response(401, {}, {});
      }
    });

    //TODO: Implement on the server
    this.post('/time-clock-entries', function (schema, request) {
      if (request.requestHeaders.Authorization) {
        var attributes = this.normalizedRequestAttrs();
        var timeClockEntry = schema.timeClockEntries.create(attributes);
        return timeClockEntry;
      } else {
        return new _emberCliMirage.Response(401, {}, {});
      }
    });

    //TODO: Implement on the server
    this.patch('/time-clock-entries/:timeClockEntryId', function (schema, request) {
      if (request.requestHeaders.Authorization) {
        var attributes = this.normalizedRequestAttrs();
        var timeClockEntry = schema.timeClockEntries.find(attributes.id);
        for (var propertyName in attributes) {
          timeClockEntry[propertyName] = attributes[propertyName];
        }
        timeClockEntry.save();
        return timeClockEntry;
      } else {
        return new _emberCliMirage.Response(401, {}, {});
      }
    });

    //TODO: Implement on the server
    this.get('/projects', function (schema, request) {
      if (request.requestHeaders.Authorization) {
        var projects = schema.projects.where({ profileId: request.queryParams.profileId });
        return projects;
      } else {
        return new _emberCliMirage.Response(401, {}, {});
      }
    });

    //TODO: Implement on the server unless the come down with the projects
    this.get('/tasks', function (schema, request) {
      if (request.requestHeaders.Authorization) {
        var _tasks = schema.tasks.where({ projectId: request.queryParams.projectId });
        return _tasks;
      } else {
        return new _emberCliMirage.Response(401, {}, {});
      }
    });

    //this.passthrough(path1, path2…, options)
    // By default, if your Ember app makes a request that is not defined in your server config, Mirage will throw an error. You can use passthrough to whitelist requests, and allow them to pass through your Mirage server to the actual network layer.
    //
    // Put all passthrough config at the bottom of your config.js file, to give your route handlers precedence.
    // To ignore paths on your current host (as well as configured namespace), use a leading /:
    //
    // this.passthrough('/addresses');
    // You can also pass a list of paths, or call passthrough multiple time:
    //
    // this.passthrough('/addresses', '/contacts');
    // this.passthrough('/something');
    // this.passthrough('/else');
    // this.passthrough('/authenticate');
  };
});