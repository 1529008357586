define('time-clock-client/components/verify-admin', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    session: _ember['default'].inject.service(),

    signOut: function signOut() {
      var _this = this;

      var _getProperties = this.getProperties('password');

      var password = _getProperties.password;

      // TODO: Once the custom session invalidate is working via TokenAuthenticator
      // then use the following commented out code instead of invalidateAdmin()
      // this.get('session').invalidate('authenticator:token').then( () => {
      this.get('session').invalidateAdmin(password).then(function () {
        _this.get('session').invalidate();
      }, function (err) {
        _this.get('flash').danger('Verfication failed');
      });
    },

    authenticateUser: function authenticateUser() {
      var _this2 = this;

      var _getProperties2 = this.getProperties('password');

      var password = _getProperties2.password;

      this.get('session').authorized(password).then(function () {
        _this2.get('router').transitionTo('profile-select');
        _ember['default'].$('#navbar').collapse('hide');
      }, function (err) {
        _this2.get('flash').danger('Verfication failed');
      });
    },

    actions: {
      cancel: function cancel() {
        _ember['default'].$('#navbar').collapse('hide');
        history.back();
      },

      verify: function verify() {
        if (this.get('action') === "sign-out") {
          this.signOut();
        } else if (this.get('action') === "verify") {
          this.authenticateUser();
        } else {
          console.log('Invalid action: verify-admin#verify()');
        }
      }
    }
  });
});