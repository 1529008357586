define('time-clock-client/controllers/application', ['exports', 'ember', 'time-clock-client/controllers/base-controller', 'moment'], function (exports, _ember, _timeClockClientControllersBaseController, _moment) {
  exports['default'] = _timeClockClientControllersBaseController['default'].extend({
    dropdownOpen: false,

    init: function init() {
      this.updateTime();
    },

    updateTime: function updateTime() {
      var _this = this;
      _ember['default'].run.later(function () {
        _this.set('localTime', (0, _moment['default'])().format("dddd, MMMM Do YYYY, h:mm:ss a"));
        _this.updateTime();
      }, 1000);
    },

    localTime: new Date().toLocaleTimeString(),

    profileName: _ember['default'].computed('profile.model', function () {
      if (!this.get('profile.model')) {
        return '';
      }
      return this.get('profile.model.name');
    }),

    actions: {

      signIn: function signIn() {
        this.transitionToRoute('sign-in');
      }

    }
  });
});
//app/controllers/application.js