define('time-clock-client/controllers/profile-select', ['exports', 'time-clock-client/controllers/base-controller'], function (exports, _timeClockClientControllersBaseController) {
  exports['default'] = _timeClockClientControllersBaseController['default'].extend({
    actions: {
      transitionToAssociateSignIn: function transitionToAssociateSignIn(profile) {
        this.transitionToRoute('profile.associate-sign-in', profile);
      }
    }
  });
});
//app/controllers/profile-select.js
// import Ember from 'ember';