define('time-clock-client/components/sign-in', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    session: _ember['default'].inject.service(),

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var _getProperties = this.getProperties('email', 'password');

        var email = _getProperties.email;
        var password = _getProperties.password;

        this.get('session').authenticate('authenticator:token', { identification: email, password: password }).then(function () {
          _this.get('router').transitionTo('profile-select');
        }, function (err) {
          _this.get('flash').danger('Error obtaining token: ' + err.responseText);
        });
      }
    }

  });
});