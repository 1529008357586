define("time-clock-client/mirage/factories/associate", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  exports["default"] = _emberCliMirage.Factory.extend({
    pin: function pin(i) {
      var is = i.toString();
      return is + is + is + is;
    },

    firstName: function firstName() {
      return faker.name.firstName();
    },

    lastName: function lastName(i) {
      return "Pin:" + this.pin; //i.toString();
    },

    email: function email(i) {
      return "associate" + i + "@acme.com";
    },

    projectEnabled: function projectEnabled() {
      return false;
    }
  });
});