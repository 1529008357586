define("time-clock-client/services/session", ["exports", "ember", "ember-simple-auth/services/session"], function (exports, _ember, _emberSimpleAuthServicesSession) {
  exports["default"] = _emberSimpleAuthServicesSession["default"].extend({

    store: _ember["default"].inject.service(),

    //TODO: DLM: Work on overriding invalidate in the token authenticator
    invalidateAdmin: function invalidateAdmin(password) {
      return this.request("/invalidate", 'POST', JSON.stringify({ password: password }));
    },

    //TODO: Check to see if there is a way to hook into the authorize function instead of doing this
    authorized: function authorized(password) {
      return this.request("/authorized", 'POST', JSON.stringify({ password: password }));
    },

    authenticateAssociate: function authenticateAssociate(id, pin) {
      return this.request("/associate-authenticate", 'POST', JSON.stringify({ id: id, pin: pin }));
    },

    invalidateAssociate: function invalidateAssociate(id) {
      return this.request("/associate-invalidate", 'PATCH', JSON.stringify({ id: id }));
    },

    request: function request(url, method, data) {
      var _this = this;

      return new _ember["default"].RSVP.Promise(function (resolve, reject) {
        _ember["default"].$.ajax({
          url: url,
          method: method,
          data: data,
          dataType: 'json',
          contentType: 'application/json',
          beforeSend: function beforeSend(request) {
            _this.authorize('authorizer:token', function (headerName, headerValue) {
              request.setRequestHeader(headerName, headerValue);
            });
          },
          success: function success(response) {
            resolve(response);
          },
          error: function error(reason) {
            reject(reason);
          }
        });
      });
    }

  });
});
//app/services/session.js