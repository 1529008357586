define('time-clock-client/router', ['exports', 'ember', 'time-clock-client/config/environment'], function (exports, _ember, _timeClockClientConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _timeClockClientConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('sign-in');
    this.route('profile-select');
    this.route('verify');
    this.route('sign-out');
    this.route('profile', { path: '/:profile_id' }, function () {
      this.route('associate-sign-in');
      this.route('time-clock', { path: '/time-clock/:associateId' });
    });
  });

  exports['default'] = Router;
});