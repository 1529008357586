define('time-clock-client/mixins/ajax-error', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    ajaxError: function ajaxError(error) {
      if (!error) {
        _ember['default'].Logger.warn('No (valid) error object provided! ajaxError function must be called with the error object as its argument.');
        return;
      }

      // Depending whether the mixin is used in controller or route
      // we need to use different methods.
      var transitionFunc = this.transitionToRoute || this.transitionTo,
          couldHandleError = false;

      switch (this._getStatusCode(error)) {
        case 401:
          transitionFunc.call(this, 'auth.logout');
          couldHandleError = true;
          break;
        case 404:
        case 500:
          // Here we trigger a service to show an server error message.
          // This is just an example and currently not the final implementation.
          // this.get('notificationService').show();
          couldHandleError = true;
          break;
      }

      // For all other errors just log them.
      if (!couldHandleError) {
        _ember['default'].Logger.error(error);
      }
    },

    _getStatusCode: function _getStatusCode(error) {
      // First check for jQuery error object
      var status = error.status;

      // Check for ember adapter error object if it's not a jquery error
      if (!status && error.errors && error.errors[0].status) {
        status = parseInt(error.errors[0].status);
      }

      return status;
    }

  });
});
//app/mixins/ajax-error.js
//originally lifted from http://stackoverflow.com/questions/32078951/how-to-implement-a-proper-global-http-error-handling-in-ember