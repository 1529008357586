define('time-clock-client/adapters/application', ['exports', 'ember', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _ember, _emberData, _emberSimpleAuthMixinsDataAdapterMixin) {
  // import ENV from "../config/environment";

  exports['default'] = _emberData['default'].JSONAPIAdapter.extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
    authorizer: 'authorizer:token',

    // if your rails app is on a different port from your ember app
    // this can be helpful for development.
    // in production, the host for both rails and ember should be the same.
    // host: ENV.host,

    // allows the multiword paths in urls to be underscored
    pathForType: function pathForType(type) {
      var underscored = _ember['default'].String.underscore(type);
      return _ember['default'].String.pluralize(underscored);
    },

    // allows queries to be sent along with a findRecord
    // hopefully Ember / EmberData will soon have this built in
    // ember-data issue tracked here:
    // https://github.com/emberjs/data/issues/3596
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var url = this._super.apply(this, arguments);
      var query = _ember['default'].get(snapshot, 'adapterOptions.query');
      if (query) {
        url += '?' + _ember['default'].$.param(query);
      }
      return url;
    }
  });
});
//app/adapters/application.js

// import ActiveModelAdapter from 'active-model-adapter';