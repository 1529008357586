define('time-clock-client/controllers/base-controller', ['exports', 'ember', 'time-clock-client/mixins/ajax-error'], function (exports, _ember, _timeClockClientMixinsAjaxError) {
  exports['default'] = _ember['default'].Controller.extend(_timeClockClientMixinsAjaxError['default'], {
    session: _ember['default'].inject.service(),
    profile: _ember['default'].inject.controller(),
    store: _ember['default'].inject.service(),
    flash: _ember['default'].inject.service('flash-messages'),

    hideSignInLink: _ember['default'].computed('currentRouteName', function () {
      if (this.get('currentRouteName') === "sign-in") {
        return true;
      }
      return false;
    }),

    currentRouteName: _ember['default'].computed('target', function () {
      return this.get('target.routeName');
    })

  });
});
//app/controllers/base-controller.js