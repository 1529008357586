define('time-clock-client/routes/base-route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'time-clock-client/mixins/ajax-error'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _timeClockClientMixinsAjaxError) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _timeClockClientMixinsAjaxError['default'], {
    flash: _ember['default'].inject.service('flash-messages'),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      _ember['default'].get(this, 'flash').clearMessages();
      _ember['default'].$('#navbar').collapse('hide');
    }
  });
});
// app/routes/base-route.js