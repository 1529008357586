define('time-clock-client/models/associate', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports['default'] = _emberDataModel['default'].extend({
    firstName: (0, _emberDataAttr['default'])('string'),
    lastName: (0, _emberDataAttr['default'])('string'),
    pin: (0, _emberDataAttr['default'])('string'),
    projectEnabled: (0, _emberDataAttr['default'])('boolean'),

    profile: (0, _emberDataRelationships.belongsTo)('profile'),
    timeClockEntries: (0, _emberDataRelationships.hasMany)('timeClockEntry'),

    fullName: _ember['default'].computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    })
  });
});